body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px; }

.navigation .nav-item .icon-text {
  display: none !important; }

::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #dadada; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; }

.mouse_hover_point {
  cursor: pointer; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }
