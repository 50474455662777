.receiver-column {
    text-align: left;
}
.date-column{
   text-align: right;
    
}
.letter-head {
    text-align: center;
    
}

.page {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    margin: 10mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
   
}
.subpage {
    padding: 1cm;
    border: 5px black solid;
    height: 257mm;
    outline: 1cm #cbdbe7 solid;
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;        
    }
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        
    }
}


hr {
    border-top: 1px solid black;
  }
h1 {
    font-size: 1.5em;
  }

h3 {
    font-size: 1.3em;
}

p,address,dl {
    font-size: 1em;
    text-align: justify;
}

p, address {
    line-height: 1.5;
    
}